import React from 'react';

import Icon from 'components/atoms/icons/icon';

import {
  StyledSocialList,
  StyledSocialItem,
  StyledSocialLink,
} from './SocialStyles';

const Social = ({ socialsData, fullWidth, black = false, small = false }) => (
  <StyledSocialList fullWidth={fullWidth}>
    {socialsData &&
      socialsData.map(({ id, name, url }) => (
        <StyledSocialItem key={id} small={small} black={black}>
          <StyledSocialLink href={url} target="_blank">
            <Icon name={name} small={small} black={black} />
          </StyledSocialLink>
        </StyledSocialItem>
      ))}
  </StyledSocialList>
);

export default Social;
