export const colors = {
  greyLight: 'rgb(138,139,133)',
  grey: 'rgb(102,97,91)',
  greyDark: 'rgb(89,90,82)',
  redLight: 'rgb(234,89,92)',

  pink: '#F4A9AE',
  pinkDark: '#D0A0A3',
  pinkDarkest: '#EE8C8C',
  greenLight: '#59d5d3',
  greenDark: '#7FB2A2',

  white: 'hsl(0, 0%, 100%)',
  gray: 'hsl(0, 0%, 90%)',
  black: 'hsl(0, 0%, 18%)',
  purple: 'hsl(259, 47%, 30%)',
  green: 'hsl(147, 95%, 31%)',
  red: 'hsl(358, 100%, 67%)',
  yellow: 'hsla(66, 99%, 66%, 1)',
  orange: '#f29c35',
  lightOrange: '#f2b01c',
  lightBlue: '#84eddf',
  footerBg: '#f29c35',
  copyrightsBg: '#dd8d36',
  copyrightsText: '#e8ae73',
  mobileMenu: '#ff3974',
};

export const breakpoints = {
  xs: 320,
  sm: 400,
  smm: 500,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxxl: 1800,
  xxxxl: 2200,
};

export const mq = Object.keys(breakpoints).reduce((acc, breakpoint) => {
  acc[breakpoint] = `@media (min-width: ${breakpoints[breakpoint]}px)`;
  return acc;
}, {});

export const theme = {
  colors,
  mq,
};
