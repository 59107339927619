import styled, { css } from 'styled-components';
import { Link as LinkScroll } from 'react-scroll';
import { Link } from 'gatsby-plugin-intl';

export const StyledNavigationWrapper = styled('div')`
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 9998;
  width: 100%;
  height: 70px;
  pointer-events: auto;
  user-select: auto;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mq.xl} {
    padding: 0 50px;
    height: 110px;
  }

  ${({ scrollDirection, scrolledToTop }) =>
    !scrollDirection &&
    !scrolledToTop &&
    css`
      height: 70px;
      transform: translateY(0px);
      box-shadow: 0 10px 30px -10px rgba(2, 12, 27, 0.3);
      ${({ theme }) => theme.mq.xl} {
        height: 96px;
      }
    `}

  ${({ scrollDirection, scrolledToTop }) =>
    scrollDirection &&
    !scrolledToTop &&
    css`
            height: 70px;
            transform: translateY(-70px);
            ${({ theme }) => theme.mq.xl} {
                transform: translateY(-96px));
                height: 96px;
            }
        `}
`;

export const StyledSiteName = styled('h1')`
  display: block;
`;

export const StyledLogoWrapper = styled(LinkScroll)`
  display: inline-block;
  cursor: pointer;
`;

export const StyledHamburger = styled('button')`
  cursor: pointer;
  display: block;

  ${({ theme }) => theme.mq.xl} {
    display: none;
  }
`;

export const StyledHamburgerLine = styled('span')`
  height: 3px;
  width: 28px;
  margin: 6px;
  display: block;
  background-color: ${({ theme }) => theme.colors.black};
`;

export const StyledMenuWrapper = styled('nav')`
  display: none;

  ${({ theme }) => theme.mq.xl} {
    display: flex;
  }
`;

export const StyledMenuLink = styled(Link)`
  display: block;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.black};
  margin: 0 6px;
  ${({ theme }) => theme.mq.xl} {
    margin: 0 14px;
  }
  ${({ theme }) => theme.mq.xxl} {
    margin: 0 25px;
  }
`;

export const StyledSelectLanguage = styled('div')`
  display: none;
  color: ${({ theme }) => theme.colors.black};

  ${({ theme }) => theme.mq.xl} {
    display: block;
  }
`;
