import styled, { css } from 'styled-components';

export const StyledSocialList = styled('ul')`
  margin: 0;
  padding: 0;
  display: flex;
  gap: 1rem;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      justify-content: space-between;
      width: 100%;
      ${({ theme }) => theme.mq.md} {
        justify-content: flex-end;
      }
    `}
`;

export const StyledSocialItem = styled('li')`
  list-style: none;
  width: ${({ small }) => (small ? '3rem' : '5rem')};
  height: ${({ small }) => (small ? '3rem' : '5rem')};
  display: grid;
  place-items: center;
  border: 1px solid
    ${({ theme, black }) => (black ? theme.colors.black : theme.colors.white)};
  border-radius: 0.9rem;
`;

export const StyledSocialLink = styled('a')`
  color: ${({ theme }) => theme.colors.white};
  display: grid;
  place-items: center;
`;
